import Vue from "vue";

export default {
  namespaced: true,

  state() {
    return {
      currentTab: 1,
      tabsPagination: {},
      queryString: {
        search: ""
      }
    };
  },

  mutations: {
    setCurrentTab(state, tab) {
      state.currentTab = tab;
    },
    setTabPagination(state, { tab, pagination }) {
      Vue.set(state.tabsPagination, tab, pagination);
    },
    SET_SEARCH(state, searchValue) {
      state.queryString.search = searchValue;
    }
  },

  actions: {
    async fetch(_ctx, { query }) {
      const data = await Vue.axios.get(`dashboard/trips`, {
        params: query
      });

      return data;
    },
    async passengers(
      _ctx,
      { page = 1, perPage = 5, search = "", tripId, cancelled }
    ) {
      const { data } = await Vue.axios.get(`dashboard/trips/${tripId}/stops`, {
        params: {
          page: page,
          per_page: perPage,
          search: search,
          cancelled: cancelled
        }
      });

      return data;
    },
    async assignDriverToTrip(_ctx, { id, driver_account_id }) {
      const { data } = await Vue.axios.put(`dashboard/trips/${id}/driver`, {
        driver_account_id
      });

      return data;
    },
    async trip(_ctx, { tripId, cancelled }) {
      const { data } = await Vue.axios.get(`dashboard/trips/${tripId}`, {
        params: {
          cancelled: cancelled
        }
      });
      return data;
    },
    async tripLogs(_ctx, { page = 1, perPage = 10, tripId, cancelled }) {
      const { data } = await Vue.axios.get(`dashboard/trips/${tripId}/logs`, {
        params: {
          page: page,
          per_page: perPage,
          cancelled: cancelled
        }
      });

      return data;
    },
    async createTripLog(_ctx, payload) {
      const { data } = await Vue.axios.post(
        `/dashboard/trips/${payload.tripId}/logs`,
        payload
      );

      return data;
    },
    async tripReviews(_ctx, { page = 1, perPage = 50, tripId, cancelled }) {
      const { data } = await Vue.axios.get(
        `dashboard/trips/${tripId}/reviews`,
        {
          params: {
            page: page,
            per_page: perPage,
            cancelled: cancelled
          }
        }
      );

      return data;
    },
    async update(_ctx, { id, payload }) {
      const { data } = await Vue.axios.put(`dashboard/trips/${id}`, payload);

      return data;
    },
    updateSearch({ commit }, searchValue) {
      commit("SET_SEARCH", searchValue);
    }
  },

  getters: {
    currentTab: state => state.currentTab,
    tabPagination: state => tab => state.tabsPagination[tab],
    searchQueryParams: state => tab => state.queryString || {}
  }
};
